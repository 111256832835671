@import 'src/theme/colorPalette.scss';

div.LinkedField-searching > div,
div.LinkedField-searching:hover > div,
div.LinkedField-searching:focus-within > div {
  box-shadow: 0 0 0 2px $color-search-link;
}

div.LinkedField-error > div {
  box-shadow: 0 0 0 1.25px $error;

  &:hover, &:focus-within {
    box-shadow: 0 0 0 2px $error;
  }
}

div.LinkedField-warning > div {
  box-shadow: 0 0 0 1.25px $warning;

  &:hover, &:focus-within {
    box-shadow: 0 0 0 2px $warning;
  }
}

