@import 'src/theme/colorPalette';


div.Ebba-warning {
  div.Mui-error {
    box-shadow: 0 0 0 1.25px $warning;
    ::placeholder {
      color: $warning;
      opacity: 0.6;
    }

    &:focus-within, &:hover {
      box-shadow: 0 0 0 2px $warning;
    }
  }
}

div.Mui-error {
  ::placeholder {
    color: $error80;
  }
}
