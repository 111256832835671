// Normal / not-italic
@font-face {
  font-family: 'ABCMonumentGrotesk';
  font-style: normal;
  font-weight: normal; // <=400
  src: local('ABCMonumentGroteskRegular'), url('./fonts/ABCMonumentGrotesk-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'ABCMonumentGrotesk';
  font-style: normal;
  font-weight: 500;
  src: local('ABCMonumentGroteskMedium'), url('./fonts/ABCMonumentGrotesk-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'ABCMonumentGrotesk';
  font-style: normal;
  font-weight: bold; // >=700
  src: local('ABCMonumentGroteskBold'), url('./fonts/ABCMonumentGrotesk-Bold.woff2') format('woff2');
}

// Italic styles
@font-face {
  font-family: 'ABCMonumentGrotesk';
  font-style: italic;
  font-weight: normal; // <=400
  src: local('ABCMonumentGroteskRegularItalic'), url('./fonts/ABCMonumentGrotesk-RegularItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'ABCMonumentGrotesk';
  font-style: italic;
  font-weight: 500;
  src: local('ABCMonumentGroteskMediumItalic'), url('./fonts/ABCMonumentGrotesk-MediumItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'ABCMonumentGrotesk';
  font-style: italic;
  font-weight: bold; // >=700
  src: local('ABCMonumentGroteskBoldItalic'), url('./fonts/ABCMonumentGrotesk-BoldItalic.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'ABCMonumentGrotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
