@import 'src/theme/theme.scss';

.CodeListField-single {
  * div.ghost {
    padding: 0px 10px 0px 20px;
    height: 0;
    visibility: hidden;
  }

  &:not(:focus-within) {
    // Juster bredde på kodeliste-felt til innhold når ikke fokus
    // for å ikke fange klikk utenfor feltet
    width: fit-content;

    &.CodeListField-full-width {
      width: unset;
    }

    div.sizer {
      transition: 0.1s;
    }
    * div.MuiFormControl-root {
      background-color: transparent;
    }
    * div.MuiOutlinedInput-root {
      background-color: $beige;
      outline: none;
      box-shadow: none;
      padding-right: 6px;
    }
    * input.MuiAutocomplete-input {
      width: 0 !important;
      transition: 0.1s;
    }
    transition: 0.1s;
  
    &.Ebba-empty {
      * div.MuiOutlinedInput-root {
        min-width: 4em;
      }
    }

    &.Ebba-error {
      * div.MuiOutlinedInput-root {
        background-color: $error20;
        box-shadow: 0 0 0 1.25px $error;
      }
      &:hover {
        * div.MuiOutlinedInput-root {
          box-shadow: 0 0 0 2px $error;
        }
      }
    }

    &.Ebba-warning {
      * div.MuiOutlinedInput-root {
        background-color: $warning40;
        box-shadow: 0 0 0 1.25px $warning;
      }

      &:hover {
        * div.MuiOutlinedInput-root {
          box-shadow: 0 0 0 2px $warning;
        }
      }
    }
  }  
}

.CodeListField-multiple {
  div.MuiTextField-root.Ebba-readonly {
    div.MuiOutlinedInput-root {
      opacity: 1;
      
      div.MuiChip-root {
        opacity: 0.6;
      }
    }
  }

  * div.MuiChip-root {
    background-color: $beige;
    color: $burgundy;
    z-index: 1;
    margin: 4px 2px 2px 2px;

    &.Ebba-draggable-active {
      opacity: 0.4;
    }    
  }
  * div.MuiChip-root.Mui-focusVisible {
    background-color: $brown;
  }

  * input.Ebba-hide-input-cursor {
    caret-color: transparent;
  }

  &.Ebba-error {
    * input::placeholder {
      color: $error80;
    }
  }

  &.Ebba-warning {
    * input::placeholder {
      color: $warning80 !important;
    }
  }  


  &:not(:focus-within) {
    // Juster bredde på kodeliste-felt til innhold når ikke fokus
    // for å ikke fange klikk utenfor feltet
    width: fit-content;
  
    * div.MuiChip-root {
      font-weight: normal;
      font-style: normal;
      font-size: $body1-size;
      background-color: $beige;
      margin: 4px 6px 0 2px;
      padding: 0 8px;
      height: 35px;
    }
    * svg.MuiSvgIcon-root {
      width: 0;
      height: 0;
      visibility: hidden;
    }
    div.MuiFormControl-root {
      background-color: transparent;
      transform: translateY(-4px);
    }
    * div.MuiOutlinedInput-root {
      background-color: transparent;
      outline: none;
      box-shadow: none!important;
      padding: 0;
    }
    // * input.MuiAutocomplete-input {
    //   // To allow for input to show placeholder
    //   width: unset !important;
    // }

    &.Ebba-empty {
      padding: $unit;
    }

    &.Ebba-error {
      * div.MuiChip-root {
        background-color: $error20;
        box-shadow: 0 0 0 1.25px $error;
      }
      &:hover {
        * div.MuiChip-root {
          box-shadow: 0 0 0 2px $error;
        }
      }
    }

    &.Ebba-warning {
      * div.MuiChip-root {
        background-color: $warning40;
        box-shadow: 0 0 0 1.25px $warning;
      }
      &:hover {
        * div.MuiChip-root {
          box-shadow: 0 0 0 2px $warning;
        }
      }
    }
  }

  &:focus-within {
    * div.MuiOutlinedInput-root {
      padding-top: 5.5px;
      padding-bottom: 5.5px;
    }

    &.Ebba-error {
      * div.MuiOutlinedInput-root {
        box-shadow: 0 0 0 2px $error;
      }
    }

    &.Ebba-warning {
      * div.MuiOutlinedInput-root {
        box-shadow: 0 0 0 2px $warning;
      }
    }
  }  
}

