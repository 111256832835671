@import 'src/theme/theme.scss';

.ToolInput {
  border-radius: 999px;
  background: $white;
  div.MuiOutlinedInput-root {
    border: none;
    outline: none;
    box-shadow: none;

    input {
      padding-left: 1.5em;
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }

  &.Multiline-ToolInput {
    border-radius: $unit2x;
  }

  &.NoPadding-ToolInput {
    div.MuiOutlinedInput-root {
      padding: 0;
    }
  }
}
