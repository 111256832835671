.Ebba-digits {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.Ebba-numbers input[type='number'] {
  padding-right: 4px; // Space for up-down arrows
}

div.Ebba-readonly {
  background: none;
}

.MuiFormControl-root {
  &.MuiTextField-root {
    height: fit-content;
  }
}
