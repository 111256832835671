@import 'src/theme/colorPalette.scss';

.MuiInputAdornment-root {
  .Mui-focusVisible {
    color: $burgundy;
  }
}

.DatePickerField-error {
  div {
    div {
      div.MuiOutlinedInput-root {
        box-shadow: 0 0 0 1.25px $error;

        &:focus-within, &:hover, &:active {
          box-shadow: 0 0 0 2px $error !important;
        }
      }
    }
  }
}

.DatePickerField-warning {
  div {
    div {
      div.MuiOutlinedInput-root {
        box-shadow: 0 0 0 1.25px $warning;

        &:focus-within, &:hover, &:active {
          box-shadow: 0 0 0 2px $warning !important;
        }
      }
    }
  }
}

