/* Hide separator */
.datatable-overviewtasks .MuiDataGrid-columnSeparator {
  visibility: collapse;
}

/* Remove cell bottom borders */
.MuiDataGrid-columnHeaders,
.MuiDataGrid-columnHeader,
.MuiDataGrid-cell {
  border-bottom-width: 0 !important;
}

/* Replace selected header outline with weak/strong color of title */
.MuiDataGrid-columnHeader {
  color: #9a9d90;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 9px;
  letter-spacing: 0.399561px;
  text-transform: uppercase;
}

.MuiDataGrid-columnHeader--sorted,
.MuiDataGrid-columnHeader:hover,
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell,
.MuiDataGrid-cell:focus {
  outline: solid transparent 0 !important;
  color: #480905;
}

.MuiDataGrid-columnHeaderTitleContainer > .MuiDataGrid-iconButtonContainer {
  visibility: collapse !important;
  width: 0 !important;
}

.MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle {
  z-index: 999;
}

.MuiDataGrid-row {
  border: 0px solid white;
  outline: 1px solid white;
  border-radius: 4px;
  background-color: #f9faf8;
}

.MuiDataGrid-cell {
  color: #480905;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
}

.MuiDataGrid-selectedRowCount {
  opacity: 0;
}

.MuiTablePagination-displayedRows {
  color: #480905;
}

.MuiDataGrid-toolbarContainer .MuiBadge-badge {
  background-color: #480905;
  font-size: .6em;
}


.MuiDataGrid-toolbarContainer .MuiDataGrid {
  background: #90EE90FF;
}

.search-input .MuiInputBase-root.MuiOutlinedInput-root {
  box-shadow: none;
}
.MuiDataGrid-toolbarContainer .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
  box-shadow: 0 0 0 1.25px #9A9D90;
}

.MuiDataGrid-filterFormValueInput .MuiAutocomplete-root .MuiInputBase-root .MuiChip-label {
  overflow: hidden;
}
