div.Ebba-collapsableLayout {
  // transition: 0.6s cubic-bezier(0.075, 0.9, 0.65, 1);
  // max-height: 1000px;

  &.Ebba-collapsed-vertical {
    max-height: 0;
    margin-bottom: 0 !important;
    opacity: 0;
    // transition: max-height 0.3s ease-out, margin-bottom 0.35s ease-out;
    transition: max-height 0.3s ease-out, margin-bottom 0.35s ease-out,
      opacity 0.3s ease-out;
  }
}
