$color-search-link: #b1928f;

$green: #e1f9dd;
$lightGreen: #eaeade;
// $warmGreen: #9a9d90;
$warmGreen: #C6C6C6;  
// $warmGreen06: #9a9d9099;
$warmGreen06: #C6C6C699;  
$burgundy: #480905;
$white: #fefefe;
$offWhite: #fefefe;
$lightBurgundy: #b1928f;
$terracotta: #b1928f;
$brown: #cec0b2;
$beige: #eaeadf;
$lightBeige: #f9faf8;

$enhancedBeige: #f9faf8;
$enhancedBrown: #ccb9a8;
$enhancedLightBeige: #f2f4f0;
$spot-new: #ddff6a;
$spot-pre: #dcdcfe;
$spot-change: #ffd4cb;
$spot-urgent: #ffeeb1;

$status-new: #CAE4EA;
$status-precat: #FFCD9F;
$status-control: #E1D7D1;
$status-done: #D2E8C7;

$textPrimary: #212121;
$textSecondary: #666761;


// @deprecated
$dirtyLightGreen: #f7f8f5;

$success: #4caf50cc;
$warning: #FFBD12;
$warning80: #FFCA41;
$warning60: #FFD771;
$warning40: #FFE5A0;
$warning20: #FFF2D0;
$error: #ef5350;
$error80: #F27573;
$error60: #F59896;
$error40: #F9BAB9;
$error20: #FCDDDC;
