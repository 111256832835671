@import './colorPalette.scss';

$unit: 8px;

$unit2x: 16px;

$radius-large: calc(2 * $unit);
$radius-small: $unit;

$spacing: $unit;
$spacing-large: calc(2 * $unit);

$body1-size: 0.93rem;