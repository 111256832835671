@import 'src/theme/colorPalette';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer;
}

ol.themaRules li {
  padding: .7em .7em .7em 2rem;
}

.thesaurusInfoBox {
  display: flex;
  align-items: center;
  padding: 2px 0;

  & .outerBox {
    margin:4px 4px 4px 0;
    border-radius: 8px;
    background-color: $beige;

    & .nodeCode {
      padding: 4px 8px;
      color: $burgundy;
    }
  }
  & .nodeText {
    padding-left: 4px;
    color: $burgundy;
  }
}

.autocomplete-line mark {
  font-weight: bold;
  background-color: inherit;
}

a {
  text-decoration-color: $lightBurgundy;
}