.UnstyledButton {
  border-style: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  text-decoration: none;
  /* text-align: left; */
  -webkit-appearance: none;
  -moz-appearance: none;
  align-content: unset;
  align-self: unset;
  align-items: unset;
}

.UnstyledButton:focus {
  outline: none;
}

.UnstyledButton:focus, .UnstyledButton:hover {
  cursor: pointer;
}

.UnstyledButton:active:not([disabled]) {
  opacity: 0.5;
}
