@import 'src/theme/theme.scss';

.text-changes-deleted {
  color: transparent;
  text-decoration-line: underline;
  text-decoration-color: $error;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.text-changes-added {
  color: transparent;
  text-decoration-line: underline;
  text-decoration-color: $success;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}
